/* Scanner Design */
.scanner {
    /* border: 1px solid rgb(0, 255, 221) !important; */
    
    border-radius: 5px;
}

.scanner>div {
  
    border-radius: 5px;
}

.scanner>div:nth-child(2) {
   
    min-height: auto !important;
    border-radius: 5px !important;
}

.scanner img {
    display: none;
}

.scanner button {
   
    opacity: 1;
    display: inline-block;
    padding: 10px 5px;
    background-color: rgb(34, 118, 118);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 5px;
    /* margin-top: 10px; */
}
.scanner a {
 
    min-height: 10px !important; 
    opacity: 1;
    display: inline-block;
    padding: 10px 5px;
    background-color: teal;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: none !important;
   
}

#lcorJamidScanner__dashboard_section{
    justify-content: center;
    @media (min-width: 445px) {
        display: flex;
      }
    
}
/* Scanner Design */










